import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { style: {"font-size":"14px","margin-top":"8px","margin-bottom":"-37px"} }
const _hoisted_2 = { class: "nav-bar-container" }
const _hoisted_3 = { class: "nav-bar-logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  style: {"display":"contents"}
}
const _hoisted_6 = { class: "avatar-and-client-name" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  class: "mobileHide",
  style: {"margin-left":"8px","margin-right":"16px"}
}
const _hoisted_9 = { style: {"font-weight":"400","font-size":"14px","max-width":"102px","max-height":"32px"} }
const _hoisted_10 = {
  key: 0,
  style: {"font-weight":"400","float":"left","font-size":"12px","color":"rgba(255, 255, 255, 0.6)","max-width":"102px","max-height":"32px"}
}
const _hoisted_11 = {
  key: 2,
  class: "nav-bar-empty-container"
}
const _hoisted_12 = {
  key: 0,
  style: {"background":"url('/img/dropdownTooltip.svg')","position":"fixed","width":"16.5em","height":"7.6em","right":"6px","top":"56px","z-index":"3"}
}
const _hoisted_13 = { style: {"padding":"16px","margin-bottom":"0"} }
const _hoisted_14 = ["src"]
const _hoisted_15 = { style: {"font-weight":"600","font-size":"12px","max-width":"102px","max-height":"32px","margin-top":"10px"} }
const _hoisted_16 = { style: {"padding":"16px","margin-top":"-22px","padding-top":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_list_od_docs_to_be_downloaded = _resolveComponent("list-od-docs-to-be-downloaded")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialogDownload,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogDownload) = $event)),
      width: "800",
      style: {"margin":"10px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { style: {"padding":"30px"} }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("docsToBeDownloaded")), 1),
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("envelopeContains")), 1),
                _createElementVNode("img", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogDownload = false)),
                  alt: "close",
                  src: "/img/closeBtn.svg",
                  style: {"position":"absolute","right":"21px","top":"21px","cursor":"pointer"}
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_list_od_docs_to_be_downloaded, {
                  filesToBeDownloaded: _ctx.filesToBeDownloaded,
                  linkToDownloadAllAsZip: _ctx.linkToDownloadAllAsZip
                }, null, 8, ["filesToBeDownloaded", "linkToDownloadAllAsZip"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_card, {
      class: "overflow-hidden",
      style: {"box-shadow":"none !important","border-radius":"0","margin-bottom":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar, {
          absolute: "",
          style: _normalizeStyle([{ 'background-color': this.$vuetify.theme.themes.light.colors.bcColor, 'color': this.$vuetify.theme.themes.light.colors.textColor }, {"border-radius":"0"}]),
          "elevate-on-scroll": "",
          "scroll-target": "#scrolling-techniques-7"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.prefix + _ctx.mainIcon,
                  alt: ""
                }, null, 8, _hoisted_4)
              ]),
              (this.$vuetify.theme.themes.light.colors.showCenterText == '1')
                ? (_openBlock(), _createBlock(_component_v_toolbar_title, {
                    key: 0,
                    class: "mdHide",
                    style: {"text-align":"center","font-size":"16px","min-width":"132px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("electronicSignature")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showPartInNavbarWithUserInfo && !_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (this.$vuetify.theme.themes.light.colors.showLanguageChoose == '1')
                      ? _withDirectives((_openBlock(), _createElementBlock("select", {
                          key: 0,
                          class: "mobileHide",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                          id: "languageSelect",
                          style: {"margin-right":"1em"}
                        }, _cache[4] || (_cache[4] = [
                          _createElementVNode("option", { value: "cs" }, "cs-CZ", -1),
                          _createElementVNode("option", { value: "en" }, "en-US", -1)
                        ]), 512)), [
                          [_vModelSelect, _ctx.$i18n.locale]
                        ])
                      : _createCommentVNode("", true),
                    (this.$vuetify.theme.themes.light.colors.showDocumentInfo == '1' && _ctx.downloadDocVar && _ctx.showPartInNavbarWithUserInfoDownloadDialog && _ctx.showPartInNavbarWithUserInfoDownloadDialogOwn)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialogDownload = true)),
                          src: "../img/docInfo.svg",
                          style: {"cursor":"pointer","margin-right":"32px"},
                          alt: "docInfo"
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("img", {
                        class: "descriptionOfClientImg",
                        src: this.$vuetify.theme.themes.light.colors.avatar,
                        alt: "userAvatarDef"
                      }, null, 8, _hoisted_7),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.nameOfClient), 1),
                        (this.$vuetify.theme.themes.light.colors.showDescOfClient == '1')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.descOfClient), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11))
            ])
          ]),
          _: 1
        }, 8, ["style"]),
        _createVNode(_component_v_sheet, {
          id: "scrolling-techniques-7",
          class: "overflow-y-auto",
          "max-height": "600"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { style: {"height":"64px"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showUserDetail)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_v_row, { style: {"margin-top":"1px","margin-bottom":"1px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "3" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "descriptionOfClientImg",
                      src: this.$vuetify.theme.themes.light.colors.avatar,
                      style: {"cursor":"pointer"},
                      alt: "userAvatarDef"
                    }, null, 8, _hoisted_14)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { style: {"text-align":"left"} }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.nameOfClient), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _cache[5] || (_cache[5] = _createElementVNode("hr", { style: {"border":"1px solid #F2F2F2"} }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "6" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("p", { style: {"cursor":"pointer","font-size":"12px","font-weight":"600","color":"#888888"} }, [
                      _createElementVNode("img", {
                        id: "logoutImg",
                        src: "/img/logout.svg",
                        alt: "logout",
                        style: {"margin-bottom":"-1px"}
                      }),
                      _createTextVNode(" Odhlásit se")
                    ], -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_col, { style: {"text-align":"right"} }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      style: _normalizeStyle([{"cursor":"pointer","font-weight":"400","font-size":"12px","text-decoration":"underline"}, {'color':this.$vuetify.theme.themes.light.colors.menuAboutApp}])
                    }, "O aplikaci", 4)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])), [
          [_directive_click_outside, _ctx.clickOutside]
        ])
      : _createCommentVNode("", true)
  ]))
}