export default class Config {

    public apiUrl: string;
    public timeToWaitBeforeRedirect: number;
    public periodOfInactivityRequiredToLogOutInMinutes: number;
    public gtmId: string;

    private static instance: Config|undefined;

    public static async get(): Promise<Config> {
        if(this.instance)
            return this.instance;

        this.instance = await this.load();
        return this.instance;
    }

    private static async load() : Promise<Config> {
        let response = await fetch("/config/configuration.json");
        let config = await response.json();
        return config as Config;
    }
}