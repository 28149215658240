import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "mainDiv",
  style: {"background":"#FFFFFF","box-shadow":"0px 0px 20px rgba(0, 0, 0, 0.1)","border-radius":"8px","padding":"48px","margin":"auto","margin-top":"-26em","max-width":"900px","text-align":"center","position":"sticky","z-index":"2","margin-bottom":"80px"}
}
const _hoisted_4 = { style: {"color":"#20252B","font-size":"16px","font-weight":"400","margin-top":"10px"} }
const _hoisted_5 = {
  key: 0,
  class: "footerText"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_loading_view = _resolveComponent("loading-view")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_navbar, { showPartInNavbarWithUserInfo: false }),
        _createElementVNode("img", {
          src: this.$vuetify.theme.themes.light.colors.bcImage,
          style: {"width":"102vw","height":"450px","object-fit":"cover"},
          alt: "bcImage"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: "/img/404.svg",
            alt: "404"
          }, null, -1)),
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pageNotFound")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("pleaseInsertCorrectFormatOfURL")), 1)
        ]),
        (this.$vuetify.theme.themes.light.colors.showFooter == '1')
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("footerInfo")), 1))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createVNode(_component_loading_view)
      ]))
}