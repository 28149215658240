import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_copyright = _resolveComponent("copyright")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_navbar, {
      "name-of-client": _ctx.clientName,
      "desc-of-client": _ctx.descClient,
      showPartInNavbarWithUserInfo: _ctx.showUserPart,
      showPartInNavbarWithUserInfoDownloadDialog: false
    }, null, 8, ["name-of-client", "desc-of-client", "showPartInNavbarWithUserInfo"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_copyright)
    ])
  ], 64))
}