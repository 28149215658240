import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import i18n from '@/i18n';
import Log from "@/assets/ts/Log";
import { createGtm } from 'vue-gtm';
import Config from "@/assets/ts/Config";

loadFonts();

async function getGtmId(){
    try {
        const config = await Config.get();
        return config.gtmId;
    }
    catch (error) {
        console.error("Error fetching GTM ID from config:", error);
        return "";
    }
}

let app = createApp(App);
app.config.errorHandler = (err, instance, info) => {
    // TODO: Move this logic to central error handling class
    if(err instanceof Error) {
        Log.error(err.name);
        Log.error(err.message);
        if(err.stack)
            Log.error(err.stack);
    } else {
        console.log(err); // TODO: Improve this, to log using central logger
    }

    // TODO: Navigate to error dialog
}

getGtmId().then(gtmId => {
    app.use(createGtm({
        id: gtmId, // Use the retrieved GTM ID here
        vueRouter: router
    }));
})
    .catch(error => {
        console.error("Error initializing GTM:", error);
        // Handle GTM initialization error (e.g., log error to server)
    });

const gtmId =  Config.get().then(x => x.gtmId)

app.use(router)
    .use(vuetify)
    .use(i18n)
    .mount("#app");
